import { FC, useMemo } from 'react'

import { formatCurrency } from '@/core/utils/formatCurrency'

import { Countdown } from './Countdown'

import desktopBg from '@/assets/desktop-banner-bg.png'
import mobileBg from '@/assets/mobile-banner-bg.png'
import { appConfig } from '@/config'

type LeaderboardBannerProps = {
  end?: number
  prizePool: number
}

export const LeaderboardBanner: FC<LeaderboardBannerProps> = ({
  end = 0,
  prizePool,
}) => {
  const isActiveLeaderboard = useMemo(
    () => end - Math.floor(new Date().getTime() / 1000) > 0,
    [end]
  )

  if (!isActiveLeaderboard) {
    return (
      <div className="flex justify-center items-center text-sm md:text-base text-center px-3 md:px-32 pt-4 md:pt-8 pb-2 md:pb-6">
        Winners will receive their share of the prize pool within 48 hours,
        airdropped directly to their wallets. Thank you for participating!
      </div>
    )
  }

  if (!appConfig.showTradingCompetitionBanner) {
    return null
  }

  return (
    <div
      className="relative rounded-3xl"
      style={{
        width: '100%',
        height: undefined,
        aspectRatio: 2.94,
      }}
    >
      <picture className="w-full h-full absolute z-0 bg-cover bg-center bg-no-repeat">
        <source media="(max-width: 586px)" srcSet={mobileBg} />
        <source media="(min-width: 587px)" srcSet={desktopBg} />
        <img
          loading="lazy"
          fetchpriority="low"
          className="w-full"
          src={desktopBg}
          alt=""
        />
      </picture>
      <div className="w-full h-full absolute z-10 flex justify-between">
        <div className="w-[36%] h-full flex flex-col ml-[6%] md:ml-10 pt-[7.3%] md:pt-16">
          <span className="text-[3.6vw] md:text-2xl  font-bold mb-[3.1%] md:mb-2">
            Ape in now!{' '}
            <span className="absolute left-[50%] top-[41%] md:top-[45%] opacity-70">
              🦍
            </span>
          </span>
          <span className="text-[2.8vw] md:text-lg">
            Get into the Top 100 to win your share!
          </span>
        </div>
        <div className="w-[36%] h-full flex flex-col font-bold pl-[1.5%] md:pl-2 pt-[6%] md:pt-14">
          <div className="flex flex-col  md:mb-3">
            <span className="text-[2.3vw] md:text-sm">Prize pool:</span>
            <span className="text-[4.5vw] md:text-4xl text-primary-500">
              {formatCurrency(String(prizePool))}
              <span className="text-[3vw] md:text-2xl"> RPK</span>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[2.3vw] md:text-sm text-cool-gray-200">
              Ends in:
            </span>
            <span className="text-[3vw] md:text-xl text-cool-gray-100">
              <Countdown end={end} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
