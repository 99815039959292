import { FC, useCallback, useRef } from 'react'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { useWalletClient } from 'wagmi'

import { Skeleton } from '@/core/components/common/Skeleton'
import { rem } from '@/core/utils/utils'

import { Activity } from '../../hooks/useActivities'
import { Chart } from '../Chart'
import { ChartSkeleton } from '../Chart/ChartSkeleton'
import { RowSkeleton } from '../skeletons/RowSkeleton'
import { ActivityListRow } from './ActivityListRow'

import { appConfig } from '@/config'

type ActivityListProps = {
  activities: Activity[]
  isActivitiesLoading: boolean
}

export const ActivityList: FC<ActivityListProps> = ({
  activities,
  isActivitiesLoading,
}) => {
  const listRef = useRef<HTMLDivElement | null>(null)
  const { data: walletData } = useWalletClient()

  const rowVirtualizer = useWindowVirtualizer({
    count: activities.length,
    estimateSize: useCallback(() => 30, []),
    scrollMargin: listRef.current?.offsetTop ?? 0,
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col w-full md:self-start md:sticky top-0 col-span-1 md:pt-[132px] md:-mt-[132px] bg-cool-gray-900 z-10">
        {isActivitiesLoading ? (
          <Skeleton className="w-16 h-5 mt-1 ml-4 md:ml-0" />
        ) : (
          <div className="text white font-bold mb-4 px-4 md:px-0">Activity</div>
        )}
        <div className="w-full mb-6 ml-2 md:ml-0">
          {isActivitiesLoading ? (
            <ChartSkeleton />
          ) : (
            <Chart type={activities?.[0]?.side} activities={activities} />
          )}
        </div>
        <div className="w-full h-full" ref={listRef}>
          <div className="flex text-xs font-normal text-cool-gray-300 mb-4 px-2">
            {isActivitiesLoading ? (
              <>
                <div className="w-12 mb-0.5">
                  <Skeleton className="w-8 h-2.5" />
                </div>
                <div className="flex flex-1 mr-8">
                  <Skeleton className="w-20 h-2.5" />
                </div>
                <div className="flex-1 flex justify-end">
                  <Skeleton className="w-10 h-2.5" />
                </div>
                <div className="w-24 flex justify-end">
                  <Skeleton className="w-12 h-2.5" />
                </div>
                <div className="w-7 flex justify-end" title="Transaction">
                  <Skeleton className="w-4 h-2.5" />
                </div>
              </>
            ) : (
              <>
                <div className="w-12">Time</div>
                <div className="flex flex-1 mr-8">Holder</div>
                <div className="flex-1 text-right">Amount</div>
                <div className="w-24 text-right">
                  Price({appConfig.currency})
                </div>
                <div className="w-7 text-right" title="Transaction">
                  Txn
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {isActivitiesLoading ? (
          <div
            className="virtual-container"
            style={{
              height: rem(rowVirtualizer.getTotalSize()),
              width: '100%',
              position: 'relative',
            }}
          >
            {[...Array(15)].map((_: unknown, index: number) => (
              <RowSkeleton key={index} />
            ))}
          </div>
        ) : (
          <>
            <div
              className="virtual-container relative -top-2 md:top-4"
              style={{
                height: rem(rowVirtualizer.getTotalSize()),
                width: '100%',
              }}
            >
              {activities &&
                rowVirtualizer
                  .getVirtualItems()
                  .map((virtualRow) => (
                    <ActivityListRow
                      accountAddress={walletData?.account.address}
                      key={virtualRow.index}
                      item={activities[virtualRow.index]}
                      virtualRow={virtualRow}
                      scrollMargin={rowVirtualizer.options.scrollMargin}
                    />
                  ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
