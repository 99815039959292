import { TrackingEvent } from './enums'

import { EventName } from './interface'
import { GA4Driver } from './platform/ga'
import { TrackingSystem } from './tracking'

const AnalyticsEvents: TrackingEvent[] = [
  TrackingEvent.Buy,
  TrackingEvent.Sell,
  TrackingEvent.ClickBuyOrSellButton,
  TrackingEvent.DrawerConnectWalletButton,
  TrackingEvent.DrawerGetRPK,
  TrackingEvent.DrawerWithinBudget,
  TrackingEvent.ClickTradingCompetitionBanner,
  TrackingEvent.ClickOpenChatButton,
  TrackingEvent.ClickSendStarterQuestionButton,
  TrackingEvent.ClickSendChatQuestionButton,
]

const Tracking = TrackingSystem.getInstance(
  [new GA4Driver(AnalyticsEvents)].filter(Boolean)
)

export { Tracking, TrackingEvent }
export type { EventName }
