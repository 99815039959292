export enum TrackingEvent {
  Buy = 'buy',
  Sell = 'sell',
  ClickBuyOrSellButton = 'ClickBuyOrSellButton',
  DrawerConnectWalletButton = 'DrawerConnectWalletButton',
  DrawerGetRPK = 'DrawerGetRPK',
  DrawerWithinBudget = 'DrawerWithinBudget',
  ClickTradingCompetitionBanner = 'ClickTradingCompetitionBanner',
  ClickOpenChatButton = 'ClickOpenChatButton',
  ClickSendStarterQuestionButton = 'ClickSendStarterQuestionButton',
  ClickSendChatQuestionButton = 'ClickSendChatQuestionButton',
}
